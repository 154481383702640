import React, { CSSProperties } from "react";
import { NavigationProps } from "./ScreenManager";
import { ScreenComponent } from "./ScreenComponent";
import { OnelockFrame } from "../components/OnelockFrame";
import { UserInfoBar } from "../components/UserInfoBar";
import { MenuButton } from "../components/MenuButton";
import { LockoutButton } from "../components/LockoutButton";
import { OneLockAPI, Door } from "../lib/api";
import { LoadingSpinner } from "../components/LoadingSpinner";

const buttonStyle: CSSProperties = {
  // fontFamily2: "Segoe UI",
  fontWeight: "bold",
  fontSize: "18px",
  // borderStyle: "solid",
  borderStyle: "none",
  borderWidth: "2px",
  textAlign: "center",
  width: "100%",
  // margin: "6px",
  margin: "8px 8px 0 0",
  padding: "16px",
  // backgroundColor: "#878787",
  backgroundColor: "#666",
  color: "#fff",
  textTransform: "uppercase",
};

type OtherRoomsState = {
  loaded: false;
} | {
  loaded: true;
  doors: Door[];
};

// export class Main extends React.Component<NavigationProps, {}> {
export class OtherRooms extends ScreenComponent<never, OtherRoomsState> {
  public constructor(props) {
    super(props);
    this.state = { loaded: false };
    // this.hideMenu = true;
    // super(props);
    // this.state = {
    //   menu: "Main",
    //   // menu: "LockDown",
    //   navigateData: null,
    //   lockdownStatus: {},
    //   doorStatus: {},
    // };
  }

  // navigate = (menu, navigateData) => {
  //   this.setState({ menu, navigateData });
  // };

  public componentDidMount() {
    this.refresh();
  }

  private async refresh() {
    const doors = await OneLockAPI.getDoors();
    this.setState({ loaded: true, doors });
  }

  public render() {
    const backButton = (
      <button
        // onClick={() => this.navigate(this.state.navigateData.back[0], this.state.navigateData.back[1])}
        onClick={() => this.props.back()}
        style={Object.assign({}, buttonStyle, {
          backgroundColor: "#000",
          borderStyle: "none",
          fontSize: "24px",
          padding: "20px",
          margin: 0,
          // bottom: 0,
          // position: "absolute",
        })}
      >
        BACK
      </button>
    );

    return (
      <OnelockFrame fixedFooter footer={backButton}>
        {!this.state.loaded ? (
          <LoadingSpinner fullscreen />
        ) : (
          <div>
            <div style={{ margin: "20px" }}>
              {this.state.doors.map(d => (
                <MenuButton key={d.id} value={d.name} onClick={() => this.props.push("RoomInfo", { doorID: d.id })} />
              ))}
            </div>
          </div>
        )}
      </OnelockFrame>
    );
  }
}
