import React from "react";

interface MenuButtonProps {
  value: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export class MenuButton extends React.Component<MenuButtonProps> {
  public render() {
    return (
      <button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={{
          // fontFamily: "Segoe UI",
          fontWeight: "bold",
          fontSize: "18px",
          borderStyle: "none",
          borderWidth: "2px",
          textAlign: "center",
          width: "100%",
          minHeight: "56px",
          margin: "8px 8px 0 0",
          // padding: this.props.tight ? "8px" : "16px",
          backgroundColor: "#666",
          color: "#fff",
          textTransform: "uppercase",
        }}
      >
        {this.props.value}
      </button>
    );
  }
}
