import React from "react";

interface LockoutButtonProps {
  onClick?: () => void;
}

export class LockoutButton extends React.Component<LockoutButtonProps> {
  public render() {
    return (
      <div
        style={{
          background: "repeating-linear-gradient(-45deg, #fff, #fff 20px, #f21f2c 20px, #f21f2c 40px)",
          padding: "28px",
          width: "76%",
          margin: "0 auto",
          alignSelf: "center",
        }}
        // onClick={() => this.props.navigate("LockDown", { back: ["Main"] })}
      >
        <div
          style={{  
            background: "white",
            textAlign: "center",
            width: "90%",
            margin: "0 auto",
            fontWeight: "bold",
            // fontFamily: "Segoe UI",
            fontSize: "25px",
            padding: "6px",
          }}
        >
          LOCKOUT MENU
        </div>
      </div>
      // </div>
    );
  }
}
