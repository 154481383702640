import React from "react";
import { NavigationProps } from "./ScreenManager";
import { ScreenComponent } from "./ScreenComponent";
import { OnelockFrame } from "../components/OnelockFrame";
import { UserInfoBar } from "../components/UserInfoBar";
import { MenuButton } from "../components/MenuButton";
import { LockoutButton } from "../components/LockoutButton";
import { LoadingSpinner } from "../components/LoadingSpinner";

const buttonStyle: React.CSSProperties = {
  fontWeight: "bold",
  fontSize: "18px",
  borderStyle: "none",
  borderWidth: "2px",
  textAlign: "center",
  width: "100%",
  margin: "8px 8px 0 0",
  padding: "16px",
  backgroundColor: "#666",
  color: "#fff",
  textTransform: "uppercase",
};

export class RequestAccess extends ScreenComponent<never, { loaded: boolean }> {
  public constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  public componentDidMount() {
    setTimeout(() => this.setState({ loaded: true }), 450);
  }

  public componentWillUnmount() {}

  public render() {
    const backButton = (
      <button
        onClick={() => this.props.back()}
        style={{ ...buttonStyle, 
          backgroundColor: "#000",
          borderStyle: "none",
          fontSize: "24px",
          padding: "20px",
          margin: 0,
        }}
      >
        BACK
      </button>
    );

    return (
      <OnelockFrame fixedFooter footer={backButton}>
        {!this.state.loaded ? (
          <LoadingSpinner fullscreen />
        ) : (
          <div>
            <div style={{ margin: "20px" }}>
              <div>
                <button disabled style={{ ...buttonStyle, backgroundColor: "#fff", color: "#000" }}>
                  NO OTHER ROOMS FOUND
                </button>
              </div>
            </div>
          </div>
        )}
      </OnelockFrame>
    );
  }
}
