import React, { CSSProperties } from "react";
import { ScreenComponent } from "./ScreenComponent";
import { OnelockFrame } from "../components/OnelockFrame";
import { UserInfoBar } from "../components/UserInfoBar";
import { MenuButton } from "../components/MenuButton";
import { LockoutButton } from "../components/LockoutButton";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { User, DoorState } from "../lib/api";
import { OnelockState } from "../lib/state";

const buttonStyle: CSSProperties = {
  // fontFamily: "Segoe UI",
  // fontFamily: "Selawik",
  fontWeight: "bold",
  fontSize: "18px",
  // borderStyle: "solid",
  borderStyle: "none",
  borderWidth: "2px",
  textAlign: "center",
  width: "100%",
  // margin: "6px",
  margin: "8px 8px 0 0",
  padding: "16px",
  // backgroundColor: "#878787",
  backgroundColor: "#666",
  color: "#fff",
  textTransform: "uppercase",
};

type MainState = {
  doorState?: DoorState;
  action?: string;
} & (
  | { loaded: false }
  | {
    loaded: true;
    user: User;
  });

export class Main extends ScreenComponent<never, MainState> {
  public constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  public async componentDidMount() {
    OnelockState.me(false).then(user => {
      if (!this.state.loaded && user) {
        this.setState({ loaded: true, user });
      }
    });
    const user = await OnelockState.me(true);
    this.setState({ loaded: true, user });

    this.refresh();
  }

  public setHomeroomState = async (locked: boolean) => {
    if (!this.state.loaded) {
      throw new Error("Can't set door state when not loaded!");
    }
    try {
      this.setState({ action: locked ? "Locking..." : "Unlocking..." });
      await OnelockState.setDoorState(this.state.user.door.id, locked);
      const doorState = await OnelockState.pollDoorState(this.state.user.door.id, !locked);
      this.setState({ action: undefined, doorState });
    } catch (err) {
      // TODO: Error reporting
      console.error(err);
      this.setState({ action: undefined });
    }
  };

  public async refresh() {
    if (!this.state.loaded) {
      return;
    }
    const doorState = await OnelockState.getDoorState(this.state.user.door.id);
    this.setState({ doorState });
  }

  public render() {
    if (!this.state.loaded) {
      return (
        <OnelockFrame>
          <LoadingSpinner fullscreen />
        </OnelockFrame>
      );
    }

    return (
      <OnelockFrame
        footer={
          <div style={{ padding: "20px", backgroundColor: "#fff" }}>
            <LockoutButton />
          </div>
        }
      >
        {/* // <OnelockFrame footer={backButton}> */}

        <div>
          <div style={{ margin: "20px" }}>
            <UserInfoBar name={this.state.user.name} icon={this.state.user.icon} defaultDoor={this.state.user.door.name} role="TCH" numberOfDoors="3" />
            <div>
              <button
                style={Object.assign({}, buttonStyle, { backgroundColor: "#fff", color: "#000", padding: "8px" })}
                // onClick={() => this.props.navigate("MyClassroom", { back: ["Main"] })}
                disabled
              >
                {/* {true ? "ROOM B108 IS CURRENTLY LOCKED" : "ROOM B108 IS CURRENTLY UNLOCKED "} */}
                {this.state.doorState ? (
                  <div style={{ margin: "8px" }}>
                    {this.state.user.door.name} is {this.state.doorState.locked ? "locked" : "unlocked"}
                  </div>
                ) : (
                  <div style={{ width: "40px", margin: "0 auto" }}>
                    <LoadingSpinner thickness="1.5px" size="40px" />
                  </div>
                )}
              </button>
            </div>
            <MenuButton
              disabled={!!this.state.action}
              onClick={() => this.setHomeroomState(!(this.state.doorState && this.state.doorState.locked))}
              value={
                this.state.action ? (
                  <div style={{ display: "inline-flex", alignItems: "center", marginTop: "4px" }}>
                    <div style={{ marginRight: "6px" }}>
                      <LoadingSpinner color="#fff" thickness="1.5px" size="30px" />
                    </div>
                    {this.state.action}
                  </div>
                ) : this.state.doorState && this.state.doorState.locked ? (
                  "UNLOCK MY CLASSROOM"
                ) : (
                  "LOCK MY CLASSROOM"
                )
              }
            />
            <MenuButton value="My Other Rooms" onClick={() => this.props.push("OtherRooms")} />
            <MenuButton value="Request Access To New Room" onClick={() => this.props.push("RequestAccess")} />
          </div>
        </div>
      </OnelockFrame>
    );
  }
}
