import React, { CSSProperties } from "react";
import { ScreenComponent } from "./ScreenComponent";
import { OnelockFrame } from "../components/OnelockFrame";
import { MenuButton } from "../components/MenuButton";
import { DoorState, User } from "../lib/api";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OnelockState } from "../lib/state";

const buttonStyle: CSSProperties = {
  // fontFamily2: "Segoe UI",
  fontWeight: "bold",
  fontSize: "18px",
  // borderStyle: "solid",
  borderStyle: "none",
  borderWidth: "2px",
  textAlign: "center",
  width: "100%",
  // margin: "6px",
  margin: "8px 8px 0 0",
  padding: "16px",
  // backgroundColor: "#878787",
  backgroundColor: "#666",
  color: "#fff",
  textTransform: "uppercase",
};

interface RoomInfoState {
  user?: User | null;
  doorState?: DoorState;
  action?: string;
}

interface RoomInfoContext {
  doorID: string;
}

// export class Main extends React.Component<NavigationProps, {}> {
export class RoomInfo extends ScreenComponent<RoomInfoContext, RoomInfoState> {
  public constructor(props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount() {
    const userPromise = OnelockState.me();
    const doorState = await OnelockState.getDoorState(this.props.context.doorID);
    this.setState({ doorState });
    this.setState({ user: await userPromise });
  }

  public setDoorState = async (locked: boolean) => {
    try {
      this.setState({ action: locked ? "Locking..." : "Unlocking..." });
      await OnelockState.setDoorState(this.props.context.doorID, locked);
      const doorState = await OnelockState.pollDoorState(this.props.context.doorID, !locked);
      this.setState({ action: undefined, doorState });
    } catch (err) {
      // TODO: Error reporting
      console.error(err);
      this.setState({ action: undefined });
    }
  };

  public setAsHomeroom = async () => {
    await OnelockState.setHomeroom(this.props.context.doorID);
    this.setState({ user: await OnelockState.me(true) });
  }

  public render() {
    const backButton = (
      <button
        // onClick={() => this.navigate(this.state.navigateData.back[0], this.state.navigateData.back[1])}
        onClick={() => this.props.back()}
        style={Object.assign({}, buttonStyle, {
          backgroundColor: "#000",
          borderStyle: "none",
          fontSize: "24px",
          padding: "20px",
          margin: 0,
          // bottom: 0,
          // position: "absolute",
        })}
      >
        BACK
      </button>
    );

    const isHomeroom = this.state.user && this.state.user.door.id === this.props.context.doorID;

    return (
      <OnelockFrame fixedFooter footer={backButton}>
        {!this.state.doorState ? (
          <LoadingSpinner fullscreen />
        ) : (
          <div>
            <div style={{ margin: "20px" }}>
              <div>
                <button
                  style={Object.assign({}, buttonStyle, { backgroundColor: "#fff", color: "#000", padding: "8px" })}
                  // onClick={() => this.props.navigate("MyClassroom", { back: ["Main"] })}
                  disabled
                >
                  {/* {true ? "ROOM B108 IS CURRENTLY LOCKED" : "ROOM B108 IS CURRENTLY UNLOCKED "} */}
                  {this.state.doorState ? (
                    <div style={{ margin: "8px" }}>
                      {this.state.doorState.name} is {this.state.doorState.locked ? "locked" : "unlocked"}
                    </div>
                  ) : (
                    <div style={{ width: "40px", margin: "0 auto" }}>
                      <LoadingSpinner thickness="1.5px" size="40px" />
                    </div>
                  )}
                </button>
              </div>
              <MenuButton
                disabled={!!this.state.action}
                onClick={() => this.setDoorState(!(this.state.doorState && this.state.doorState.locked))}
                value={
                  this.state.action ? (
                    <div style={{ display: "inline-flex", alignItems: "center", marginTop: "4px" }}>
                      <div style={{ marginRight: "6px" }}>
                        <LoadingSpinner color="#fff" thickness="1.5px" size="30px" />
                      </div>
                      {this.state.action}
                    </div>
                  ) : this.state.doorState && this.state.doorState.locked ? (
                    "UNLOCK ROOM"
                  ) : (
                    "LOCK ROOM"
                  )
                }
              />
              {isHomeroom ? (
                <button disabled style={Object.assign({}, buttonStyle, { backgroundColor: "#fff", color: "#000" })}>
                  HOMEROOM
                </button>
              ) : (
                <MenuButton value="Set as homeroom" onClick={this.setAsHomeroom} />
              )}
            </div>
          </div>
        )}
      </OnelockFrame>
    );
  }
}
