import React from "react";
import { NavigationProps } from "./ScreenManager";

export class ScreenComponent<C = never, S = {}> extends React.Component<NavigationProps<C>, S> {
  public hideMenu: boolean = false;

  public constructor(props: NavigationProps<C>) {
    super(props);
  }
}
