import React from "react";
import { DataStore } from "../lib/datastore";
import { ScreenComponent } from "./ScreenComponent";

export class AuthLoadingScreen extends ScreenComponent {
  public async componentDidMount() {
    if (document.location.hash) {
      this.props.navigate("SignInScreen");
      return;
    }
    
    const accessToken = await DataStore.getAccessToken();
    if (!accessToken) {
      this.props.navigate("SignInScreen");
    } else {
      this.props.navigate("Main");
    }
  }

  public render() {
    return (
      <div style={{ fontFamily2: "Segoe UI", backgroundColor: "#eee", height: "100%" }}>
        <div style={{ backgroundColor: "#6195e7" }}>
          <img
            src={"/logo2.png"}
            style={{
              marginBottom: "-6px",
              paddingLeft: "20px",
              paddingRight: "20px",
              width: "90%",
            }}
          />
          <div style={{ fontFamily2: "Segoe UI", padding: "10%", backgroundColor: "#eee", height: "100%" }}>
            <div>Loading...</div>
          </div>
        </div>
      </div>
    );
  }
}
