import { DataStore } from "./datastore";

function stringifyQuery(query: { [k: string]: string }) {
  return Object.entries(query)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join("&");
}

function parseQuery(query: string): { [k: string]: string } {
  return Object.assign({}, ...query
    .split("&")
    .map(h => h.split("=").map(decodeURIComponent))
    .map(([k, v]) => k && v ? { [k]: v } : {}));
}

export class Auth {
  public static CLIENT_ID = "858560707381-c00i3sjjuj2baq5r9icekvrg4m1ime64.apps.googleusercontent.com";

  public static getAuthenticatingState() {
    // Google Login in progress
    if (window.location.hash) {
      console.log(window.location.hash);
      const query = parseQuery(window.location.hash.substr(1));
      window.location.hash = "";
      // TODO: Check state
      const idToken = query.id_token;
      const accessToken = query.access_token;
      if (!idToken) {
        return { state: "failed", message: "Missing ID token" };
      }
      return { state: "authComplete", idToken, accessToken };
    }
    
    return { state: "idle" };
  }

  public static createAuthURL() {
    return `https://accounts.google.com/o/oauth2/auth?${stringifyQuery({
      redirect_uri: document.location.origin,
      response_type: "id_token token",
      scope: "openid email profile",
      client_id: Auth.CLIENT_ID,
      fetch_basic_profile: "true",
      state: "",
    })}`;
  }
}
