import { DataStore } from "./datastore";
import { UnauthorizedError } from "./errors";

export interface User {
  id: string;
  name: string;
  type: string;
  icon: string;
  door: Door;
}

export interface Door {
  id: string;
  name: string;
}

export type DoorState = Door & {
  locked: boolean;
};

const agent = "OneLock Web Pilot 0.1";

export class OneLockAPI {
  private static url = document.location.origin.replace("//app.","//api.").replace(":3000",":9001") + "/app";

  private static async postJSON(path: string, body?: any) {
    return await (await fetch(`${OneLockAPI.url}${path}`, {
      method: "POST",
      headers: {
        accept: "application/json",
        authorization: `Bearer ${await DataStore.getAccessToken()}`,
        "content-type": "application/json",
        "x-client-version": agent,
      },
      body: JSON.stringify(body),
    })).json();
  }

  private static async getJSON(path: string) {
    const resp = await fetch(`${OneLockAPI.url}${path}`, {
      headers: {
        authorization: `Bearer ${await DataStore.getAccessToken()}`,
        "x-client-version": agent,
      },
    });
    if (resp.status === 401) {
      throw new UnauthorizedError();
    }
    if (resp.status === 404) {
      return null;
    }
    return await resp.json();
  }

  public static async login(
    googleToken: string,
    googleAccessToken?: string,
  ): Promise<{ token: string; error?: undefined } | { token?: undefined; error: string }> {
    const response = await fetch(`${OneLockAPI.url}/login`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "x-client-version": agent,
      },
      body: JSON.stringify({ googleToken, googleAccessToken }),
    });
    if (response.status !== 401 && response.status !== 200) {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
    return await response.json();
  }

  public static async me(): Promise<User | undefined> {
    return await this.getJSON("/me");
  }

  public static async setHomeroom(id: string): Promise<void> {
    return await this.postJSON("/me", { door: id });
  }

  public static async getDoors(): Promise<Door[]> {
    return await this.getJSON("/doors");
  }

  public static async getDoorState(id: string): Promise<DoorState> {
    return await this.getJSON(`/doors/${id}`);
  }

  public static async setDoorState(id: string, locked: boolean): Promise<void> {
    await this.postJSON(`/doors/${id}/state`, { locked });
  }

  public static async pollDoorState(id: string, expectedLocked: boolean): Promise<DoorState> {
    return await this.getJSON(`/doors/${id}/state/poll?locked=${!!expectedLocked}`);
  }
}
