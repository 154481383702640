import React from "react";
import { Screens } from "./screens/ScreenManager";

interface AppState {
  menu: keyof typeof Screens;
  context: any;
}

class App extends React.Component<{}, AppState> {
  public constructor(props: {}) {
    super(props);
    this.state = {
      menu: "AuthLoadingScreen",
      context: undefined,
    };
  }

  public componentDidMount = () => {
    window.onpopstate = this.onPopState;
  }

  public onPopState = (ev: PopStateEvent) => {
    if (ev.state) {
      this.setState({ menu: ev.state.menu, context: ev.state.context });
    } else {
      this.setState({ menu: "AuthLoadingScreen", context: null });
    }
  }

  public navigate = (menu: keyof typeof Screens, context: any) => {
    console.log("Navigate!", menu);
    this.setState({ menu, context });
  }

  public back = () => {
    history.go(-1);
  }

  public push = (menu: keyof typeof Screens, context: any) => {
    console.log("PUSH!", { menu, context });
    history.pushState({ menu, context }, "");
    this.setState({ menu, context });
  }

  public render() {
    const Screen = Screens[this.state.menu];
    return <Screen navigate={this.navigate} back={this.back} push={this.push} context={this.state.context as never} />;
  }
}

export default App;
