import React from "react";

interface LoadingSpinnerProps {
  size?: string;
  color?: string;
  thickness?: string;
  fullscreen?: boolean;
}

export class LoadingSpinner extends React.Component<LoadingSpinnerProps, {}> {
  public render(): JSX.Element {
    if (this.props.fullscreen) {
      return (
        <div style={{ marginTop: "10vh", textAlign: "center" }}>
          <div style={{ display: "inline-block" }}>
            <LoadingSpinner color={this.props.color} thickness={this.props.thickness} size="200px" fullscreen={false} />
          </div>
        </div>
      );
    }

    const thickness = this.props.thickness || "200px / 50";
    return (
      <div>
        <div
          style={{
            position: "relative",
            width: this.props.size,
            height: this.props.size,
            /// transform: "translate(-100px, -100px) scale(1) translate(100px, 100px)",
          }}
        >
          <div
            style={{
              position: "absolute",
              animation: "LoadingSpin 1s linear infinite",
              width: "80%",
              height: "80%",
              top: "10%",
              left: "10%",
              borderRadius: "50%",
              // boxShadow: "0 4px 0 0 #85000b",
              boxShadow: `0 calc(${thickness}) 0 0 ${this.props.color || "#000"}`,
              transformOrigin: `50% calc(50% + (${thickness}) / 2)`,
            }}
          />
        </div>
      </div>
    );
  }
}
