import { User } from "./api";

export class DataStore {
  public static async getAccessToken(): Promise<string | null> {
    return localStorage.getItem("accessToken");
  }

  public static async setAccessToken(token: string): Promise<void> {
    localStorage.setItem("accessToken", token);
  }

  public static async getUser(): Promise<User | null> {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : null;
  }

  public static async setUser(user: User | null): Promise<void> {
    localStorage.setItem("user", JSON.stringify(user));
  }
}
