import React, { useReducer } from "react";

interface UserInfoBarProps {
  name: string;
  // userIcon: ImageSourcePropType;
  icon: string;
  defaultDoor: string;
  role: string;
  numberOfDoors: string;
  onIconPress?: () => void;
}

export class UserInfoBar extends React.Component<UserInfoBarProps, {}> {
  public render() {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", backgroundColor: "#fff", padding: "8px", height: "95px", marginBottom: "10px" }}>
        <div style={{ float: "left", textAlign: "center" }}>
          <img style={{ borderRadius: "50%" }} src={this.props.icon} onClick={this.props.onIconPress} height={80} />
          <div style={{ fontSize: "12px" }}>{this.props.name}</div>
        </div>
        <div style={{ float: "left", paddingTop: "20px", textAlign: "center" }}>
          <div style={{ fontSize: "20px", color: "rgb(20, 173, 221)" }}>{this.props.defaultDoor}</div>
          <div style={{ fontSize: "10px" }}>ROOM</div>
        </div>
        <div style={{ float: "left", paddingTop: "20px", textAlign: "center" }}>
          <div style={{ fontSize: "20px", color: "rgb(20, 173, 221)" }}>TCH</div>
          <div style={{ fontSize: "10px" }}>ROLE</div>
        </div>
        <div style={{ float: "left", paddingTop: "20px", textAlign: "center" }}>
          <div style={{ fontSize: "20px", color: "rgb(20, 173, 221)" }}>{this.props.numberOfDoors}</div>
          <div style={{ fontSize: "10px" }}>DOORS</div>
        </div>
      </div>
    );
  }
}
