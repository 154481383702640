import { DataStore } from "./datastore";
import { OneLockAPI } from "./api";

export class OnelockState {
  public static async me(refresh?: boolean) {
    if (!refresh) {
      return await DataStore.getUser();
    } else {
      const user = await OneLockAPI.me();
      await DataStore.setUser(user);
      return user;
    }
  }

  public static async setHomeroom(id: string) {
    await OneLockAPI.setHomeroom(id);
  }

  public static async getDoors() {
    // TODO: Cache
    return await OneLockAPI.getDoors();    
  }

  public static async getDoorState(id: string) {
    // TODO: Fast cache
    return await OneLockAPI.getDoorState(id);
  }

  public static async pollDoorState(id: string, expectedLocked: boolean) {
    // TODO: Store in cache (but ignore cache always)
    return await OneLockAPI.pollDoorState(id, expectedLocked);
  }

  public static async setDoorState(id: string, locked: boolean) {
    // TOOD: Invalidate caches
    await OneLockAPI.setDoorState(id, locked);
  }
}
