import React from "react";

interface OnelockFrameProps {
  footer?: React.ReactNode;
  fixedFooter?: boolean;
  lockout?: boolean;
}

export class OnelockFrame extends React.Component<OnelockFrameProps, {}> {
  public render() {
    return (
      <div style={{ display: "flex", minHeight: "100vh", flexDirection: "column", backgroundColor: "#eee", height: "100%" }}>
        <div style={this.props.fixedFooter ? {} : { flex: 1 }}>
          <div style={{ backgroundColor: this.props.lockout ? "#85000b" : "#6195e7" }}>
            <img
              src={"/logo2.png"}
              style={{
                marginBottom: "-6px",
                paddingLeft: "20px",
                paddingRight: "20px",
                width: "90%",
              }}
            />
          </div>
          {this.props.children}
        </div>
        <div style={this.props.fixedFooter ? { height: "80px" } : {}} />
        <div style={this.props.fixedFooter ? { width: "100%", bottom: 0, position: "fixed" } : { width: "100%" }}>
          {this.props.footer || <div />}
        </div>
        {/* 3498DB -- "#85000b" : "#6195e7" */}
      </div>
    );
  }
}
