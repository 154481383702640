import React from "react";
import { Auth } from "../lib/auth";
import { OneLockAPI } from "../lib/api";
import { DataStore } from "../lib/datastore";
import { ScreenComponent } from "./ScreenComponent";

interface SignInScreenState {
  loginState?: string;
  error?: string;
}

export class SignInScreen extends ScreenComponent<never, SignInScreenState> {
  public constructor(props) {
    super(props);
    this.state = {};
  }

  public async componentWillMount() {
    const authState = Auth.getAuthenticatingState();
    if (authState.state === "failed") {
      this.setState({
        loginState: "loginError",
        error: "We're having trouble logging you in. Please try again later.",
      });
    } else if (authState.state === "authComplete") {
      this.setState({ loginState: "loginInProgress" });
      const loginResponse = await OneLockAPI.login(authState.idToken, authState.accessToken);
      if (!loginResponse.token) {
        this.setState({
          loginState: "loginError",
          error: "Your account was not found. Please check the account and try again.",
        });
        return;
      }
      await DataStore.setAccessToken(loginResponse.token);
      this.props.navigate("Main");
    } else if (authState.state === "idle") {
      this.setState({ loginState: "loading" });
      if (await DataStore.getAccessToken()) {
        this.props.navigate("Main");
      } else {
        this.setState({ loginState: "idle" });
      }
    }
  }

  public render() {
    const googleLoginLink = Auth.createAuthURL();

    let content = <div />;
    if (this.state.loginState === "loginError") {
      content = (
        <>
          <div style={{ backgroundColor: "white", margin: "20px" }}>
            <div style={{ height: "20px" }} />
            <div style={{ margin: "10px" }}>{this.state.error}</div>
            <div style={{ backgroundColor: "dark", padding: "1em 1.5em" }}>
              <a style={{ textDecoration: "none" }} href="/">
                <div style={{ backgroundColor: "#ccd", padding: "10px", color: "#666", textAlign: "center", fontWeight: 500 }}>
                  TRY AGAIN
                </div>
              </a>
            </div>
          </div>
        </>
      );
    } else if (this.state.loginState === "loginInProgress") {
      content = <div>Logging you in...</div>;
    } else if (this.state.loginState === "loading") {
      content = <div>Loading...</div>;
    } else {
      content = (
        <>
          <div style={{ textAlign: "center", fontSize: "30px" }}>Log in to your account</div>
          <div style={{ backgroundColor: "white", margin: "20px" }}>
            <div style={{ height: "20px" }} />
            <div style={{ backgroundColor: "dark", padding: "1em 1.5em" }}>
              <div style={{ textAlign: "center", fontWeight: 500 }}>
                <a href={googleLoginLink}>
                  <img style={{ width: "100%" }} src={"./google.png"} />
                </a>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>OR</div>
            <div style={{ backgroundColor: "dark", padding: "1em 1.5em" }}>
              <div
                style={{
                  backgroundColor: "#ddd",
                  padding: "5% 10px",
                  color: "#666",
                  textAlign: "center",
                  fontWeight: 500,
                  fontSize: "4vw",
                }}
              >
                SIGN IN WITH CODE
              </div>
            </div>
            <div style={{ height: "20px" }} />
          </div>
        </>
      );
    }

    return (
      <div style={{ fontFamily2: "Segoe UI", backgroundColor: "#eee", height: "100%" }}>
        <div style={{ backgroundColor: "#6195e7" }}>
          <img
            src={"/logo2.png"}
            style={{
              marginBottom: "-6px",
              paddingLeft: "20px",
              paddingRight: "20px",
              width: "90%",
            }}
          />
          <div style={{ fontFamily2: "Segoe UI", padding: "10%", backgroundColor: "#eee", height: "100%" }}>{content}</div>
        </div>
      </div>
    );
  }
}
