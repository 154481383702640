import { AuthLoadingScreen } from "./AuthLoadingScreens";
import { SignInScreen } from "./SignInScreen";
import React from "react";
import { Main } from "./Main";
import { OtherRooms } from "./OtherRooms";
import { RequestAccess } from "./RequestAccess";
import { RoomInfo } from "./RoomInfo";

export const Screens = {
  AuthLoadingScreen,
  SignInScreen,
  Main,
  OtherRooms,
  RequestAccess,
  RoomInfo,
};

type GetContext<T extends typeof React.Component> = React.ComponentProps<T> extends { context: infer C } ? C : never;

export interface NavigationProps<T = never> {
  navigate<S extends keyof typeof Screens>(screen: S, context?: GetContext<typeof Screens[S]>): void;
  push<S extends keyof typeof Screens>(screen: S, context?: GetContext<typeof Screens[S]>): void;
  back(): void;
  context: T;
}
